<template>
  <div class="page-box">
    <div class="fl-jcsb-aic mb-10">
      <el-radio-group
        v-model="readStatus"
        size="medium"
        class="mb20"
        @change="handleLabel"
      >
        <el-radio-button label="1">预案管理</el-radio-button>
        <el-radio-button label="2">通讯录</el-radio-button>
        <el-radio-button label="3">案例库</el-radio-button>
      </el-radio-group>
      <el-button type="info" size="medium" @click="lastPage">关闭</el-button>
    </div>
    <template>
      <components :is="emerTypeComp" />
    </template>
  </div>
</template>

<script>
import EmerAddress from "./components/emerAddress";
import EmerCassLibrary from "./components/emerCassLibrary";
import EmerPlan from "./components/emerPlan";
export default {
  components: { EmerAddress, EmerCassLibrary, EmerPlan },
  props: {},
  data() {
    return {
      readStatus: "1",
      emerTypeComp: "EmerPlan",
    };
  },
  watch: {},
  mounted() {},
  methods: {
    handleLabel() {
      if (this.readStatus == "1") {
        this.emerTypeComp = "EmerPlan";
      } else if (this.readStatus == "2") {
        this.emerTypeComp = "EmerCassLibrary";
      } else {
        this.emerTypeComp = "EmerAddress";
      }
    },
    // 上一页
    lastPage() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  // overflow: hidden;
  padding: 19px;
  box-sizing: border-box;
  background: #eef3f6;
}
</style>
