<template>
  <div>
    <div class="search-box">
      <el-form :inline="true" size="medium">
        <el-form-item label="" class="">
          <el-input
            v-model="input2"
            class="te"
            placeholder="关键字搜索"
            @change="handleQuery"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item>
          <el-date-picker
            placeholder="发布时间"
            v-model="searchForm.time"
            type="datetime"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        class="search-right-btn"
        @click="isShowDialog = true"
        >新增</el-button
      >
    </div>
    <div class="table-box">
      <el-table
        :data="tableData"
        border
        size="medium"
        height="calc(100vh - 10.7rem)"
        style="width: 100%"
      >
        <el-table-column
          align="center"
          label="序号"
          type="index"
          width="80"
        ></el-table-column>
        <el-table-column
          align="center"
          label="预案名称"
          prop="name"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="风险等级"
          prop="typeCodeCn"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="创建人"
          prop="createdUserName"
          width="300"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="创建时间"
          prop="createdTime"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="网格"
          prop="gridName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          label="区划码"
          prop="regionCode"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" @click="handleEdit(scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination_box">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :current-page="paramData.pageNo"
          :page-size="paramData.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="formData.id ? '编辑' : '新增'"
      :visible="isShowDialog"
      :destroy-on-close="true"
      @close="btnCancel"
    >
      <!-- 匿名插槽 -->
      <el-form
        ref="addForm"
        label-width="120px"
        :model="formData"
        :rules="rules"
      >
        <el-form-item label="案例名称" prop="name">
          <el-input
            v-model="formData.name"
            style="width: 80%"
            placeholder="案例名称"
          />
        </el-form-item>
        <el-form-item label="风险等级" prop="typeCode">
          <el-select
            v-model="formData.typeCode"
            style="width: 80%"
            placeholder="请选择"
            @blur="checkManager"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.value"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="网格">
          <el-input
            v-model="formData.gridName"
            style="width: 80%"
            placeholder="网格"
          />
        </el-form-item>
        <el-form-item label="区划码">
          <el-input
            v-model="formData.regionCode"
            style="width: 80%"
            placeholder="区划码"
          />
        </el-form-item>
        <el-form-item label="案例描述" prop="description">
          <el-input
            v-model="formData.description"
            style="width: 80%"
            placeholder="现住地址"
            type="textarea"
            :rows="3"
          />
        </el-form-item>
      </el-form>
      <!-- el-dialog有专门放置底部操作栏的 插槽  具名插槽 -->
      <el-row slot="footer" type="flex" justify="center">
        <!-- 列被分为24 -->
        <el-col :span="6">
          <el-button type="primary" size="small" @click="btnOK">确定</el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      isShowDialog: false,
      input2: "",
      tableData: [],
      total: 0,
      paramData: {
        SearchValue: "",
        PageNo: 1,
        PageSize: 10,
        TypeCode: "",
        description: "",
      },
      // 定义表单数据
      formData: {
        typeCode: "",
        name: "",
        regionCode: "",
        gridName: "",
      },
      // 定义校验规则
      rules: {
        name: [
          { required: true, message: "案例名称不能为空", trigger: "blur" },
        ],
        typeCode: [
          { required: true, message: "风险等级不能为空", trigger: "blur" },
        ],
        description: [
          { required: true, message: "案例描述不能为空", trigger: "blur" },
        ],
      },
      options: [],
    };
  },
  watch: {},
  mounted() {
    this.apiEmergencyPlanPage(this.paramData);
    this.getInformation();
  },
  methods: {
    //获取列表
    async apiEmergencyPlanPage(params) {
      const { data } = await this.api.apiEmergencyPlanPage(params);
      this.tableData = data.rows;
      this.total = data.totalRows;
    },
    async getInformation() {
      const { data } = await this.api.apiSysDictTypeDropDown({
        Code: "emergency_plan_level",
      });
      this.options = this.options.concat(data);
    },

    //编辑按钮
    handleEdit(val) {
      this.formData.typeCode = val.typeCode;
      this.formData.name = val.name;
      this.formData.regionCode = val.regionCode;
      this.formData.gridName = val.gridName;
      this.formData.description = val.description;
      this.formData.id = val.id;
      this.isShowDialog = true;
    },
    async handleDelete(val) {
      console.log(val);
      await this.$confirm("确定要删除吗");
      // 发送请求
      await this.api.apiEmergencyPlanDelete({ id: val.id });
      this.$message.success("删除成功");
      this.apiEmergencyPlanPage(this.paramData);
    },
    //查询
    handleQuery() {
      this.paramData.SearchValue = this.input2;
      const { data } = this.apiEmergencyPlanPage(this.paramData);
      this.tableData = data.rows;
    },
    // 分页操作
    handleSizeChange(val) {
      // this.paramData.PageNo = val;
      // this.getHiddangerPage(this.paramData);
    },
    handleCurrentChange(val) {
      this.paramData.PageNo = val;
      this.apiEmergencyPlanPage(this.paramData);
    },

    checkManager() {
      setTimeout(() => {
        this.$refs.addForm.validateField("typeCode");
      }, 100);
    },

    //编辑确定按钮
    async btnOK() {
      await this.$refs.addForm.validate();
      if (this.formData.id) {
        await this.api.apiEmergencyPlanEdit(this.formData);
      } else {
        await this.api.apiEmergencyPlanAdd(this.formData);
      }
      this.$message.success("操作成功");
      this.isShowDialog = false;
      this.apiEmergencyPlanPage(this.paramData);
    },
    //编辑取消按钮
    btnCancel() {
      this.formData = {
        typeCode: "",
        position: "",
        regionCode: "",
        gridName: "",
        description: "",
      };
      this.isShowDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  height: 100%;
  // overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  background: #eef3f6;
}
// .search-box {
//   display: flex;
//   justify-content: space-between;
// }
// .search-right-btn {
//   height: 2.1875rem;
// }
// 表格样式
// .table-box {
//   margin-top: 20px;
// }

// 搜索条样式
.search-box {
  position: relative;
  padding: 15px 15px 0;
  background: #fff;
  border-radius: 10px;
  .search-right-btn {
    position: absolute;
    right: 20px;
    top: 20px;
  }
}
/deep/ .el-input__inner {
  color: black !important;
}
.pagination_box {
  display: flex;
  justify-content: flex-end;
}
</style>
